<template>
    <section class="why-us container">
        <h2 class="title title--h2">Three easy steps…</h2>

        <div class="why-us__list">
            <div 
                v-for="(item, index) in list"
                :key="index"
                class="why-us__item"
            >
                <div class="why-us__item__icon">
                    <img :src="item.icon" alt="">
                </div>
                <div class="why-us__item__info">
                    <div class="why-us__item__title">{{ item.title }}</div>
                    <div class="why-us__item__description">
                        {{ item.description }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyUs',
    data: () => ({
        list: [
            {
                icon: require('@/assets/protection.svg'),
                title: 'Account',
                description: 'Provide basic personal information such as name and email.'
            }, {
                icon: require('@/assets/verification.svg'),
                title: 'Profile',
                description: 'Tell more about your life to attract the right girls.'
            }, {
                icon: require('@/assets/communication.svg'),
                title: 'Search filters',
                description: 'Set searching filters, choose the best girl, and start communication.'
            }
        ]
    })
}
</script>

<style lang="scss">
    .why-us {
        position: relative;

        @include xs {
            margin: 92px 0 34px 0;
        }

        @include sm {
            margin: 65px auto 34px auto;
        }

        @include md {
            margin: 86px auto 34px auto;
        }

        @include lg {
            margin: 170px auto 90px auto;
        }

        &__list {
            margin-top: 34px;

            @media (min-width: 768px) {
                display: flex;
                justify-content: space-between;
            }

            @media (min-width: 768px) and (max-width: 1439px) {
                text-align: center;
            }
        }

        &__item {
            display: flex;

            @include xs {
                margin-bottom: 76px;
                padding: 0 20px;
            }

            @media (max-width: 1439px) {
                align-items: center;
            }

            @media (min-width: 768px) and (max-width: 1439px) {
                flex-direction: column;
            }

            @include lg {
                margin-top: 44px;
            }

            &__icon {
                flex-shrink: 0;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 78px;
                height: 78px;

                @include xs {
                    margin-right: 24px;
                }

                @media (min-width: 768px) and (max-width: 1439px) {
                    margin-bottom: 20px;
                }

                @include lg {
                    margin-right: 18px;
                    margin-top: 6px;
                }

                &::before {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, #5143FE 0%, #8076FE 100%);
                    transform: matrix(-0.37, -0.93, 0.93, -0.37, 0, 0);
                    border-radius: 100%;
                }

                img {
                    width: auto;
                    height: 30px;
                }
            }

            &__info {
                @media (max-width: 991px) {
                    max-width: 186px;
                }

                @include md {
                    max-width: 212px;
                }

                @include lg {
                    max-width: 212px;
                }
            }

            &__title {
                color: #252B62;
                font-weight: 700;

                @media (max-width: 1439px) {
                    font-size: 24px;
                    line-height: 30px;
                }

                @include lg {
                    font-size: 28px;
                    line-height: 47px;
                }
            }

            &__description {
                color: #3A3576;

                @media (min-width: 768px) and (max-width: 1439px) {
                    margin-top: 4px;
                    font-size: 16px;
                }

                @include lg {
                    font-size: 16px;
                }
            }
        }
    }
</style>