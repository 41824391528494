import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VModal from 'vue-js-modal';

Vue.config.productionTip = false;

Vue.use(VModal);

const getIP = async () => {
	try {
		const response = await fetch('https://api.ipify.org/?format=json');
		const { ip } = await response.json();

		return ip;
	} catch (error) {
		console.error(error);
		return null;
	}
};

new Vue({
	router,
	render: h => h(App),
	beforeCreate: async function() {
		this.$ip = await getIP();
	},
}).$mount('#app');
