<template>
    <section class="info">
        <picture class="info__background">
            <source media="(min-width:1440px)" :srcset="require('@/assets/info-1440.png')">
            <source media="(min-width:992px)" :srcset="require('@/assets/info-992.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/info-768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/info-320.png')">
            <img :src="require('@/assets/info-320.png')" alt="Real Stories">
        </picture>

        <div class="container">
            <div class="info__label">
                <div class="info__label__text">
                    <div class="info__label__title">Leslie and Robert</div>
                    <div class="info__label__date">03.06.2021</div>
                </div>
            </div>

            <div class="info__description">
                <h2 class="title title--h2">Real Stories</h2>
                <div class="info__text">
                    Hi everybody) I decided to share my love story to inspire everybody who still think that online dating is useless.  My name is Maria. 7 years ago, I registered on Datinggod.fun. I didn’t even dream that I could find my love online. Today my husband works as a pilot. Then he was just graduating from flying school! At that time, I worked as a simple waitress, and studied to be an economist. It so happened that now I have my favorite job - a housewife! We have three kids, the youngest is 1.5 years old. The most interesting moment was our acquaintance. He sent me simple "hello"! I looked at his profile and I liked him. He said compliments, sent flowers, and wanted to get to know me better. This is how our love story began...
                </div>
                <a href="#head" class="button">Join Us</a>
            </div>
        </div>
    </section>
</template>

<script>
import Button from '@/components/Base/Button';

export default {
    name: 'Info',
    components: {
        Button
    },
    data: () => ({
        
    }),
    methods: {
        anchor() {
            console.log(123);
        }
    }
}
</script>

<style lang="scss">
    .info {
        display: flex;
        align-items: flex-end;
        position: relative;

        @include xs {
            min-height: 468px;
        }

        .container {

            @media (min-width: 768px) {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }
        }

        &__background {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include xs {
                top: 7px;
            }

            @include md {
                top: 7px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center top;
            }
        }

        &__label {
            z-index: 2;
            display: flex;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(15px);
            text-align: center;

            @include xs {
                position: absolute;
                right: 19px;
                top: 80px;
                padding: 5.5px 11.5px;
                border-radius: 15px;
            }

            @include sm {
                margin-bottom: 18px;
            }

            @media (min-width: 768px) {
                width: 100%;
                max-width: 230px;
                flex-shrink: 0;
                padding: 22px 35px;
                border-radius: 20px;
            }

            @include md {
                margin-bottom: 50px;
                margin-left: 60px;
            }

            @include lg {
                margin-bottom: 40px;
                margin-left: 110px;
            }

            &::before {
                content: '';
                border-radius: 100%;
                box-sizing: content-box;

                @include xs {
                    width: 3px;
                    height: 3px;
                    border: 3px solid #5648FE;
                    margin-right: 6px;
                    margin-top: 3px;
                    font-size: 10px;
                }

                @media (min-width: 768px) {
                    width: 4px;
                    height: 4px;
                    border: 4px solid #5648FE;
                    margin-right: 12px;
                    margin-top: 5px;
                }
            }

            &__title {
                font-weight: 500;
                text-transform: uppercase;
                color: #F7F4FD;
                line-height: 160%;

                @include xs {
                    font-size: 10px;
                }

                @media (min-width: 768px) {
                    margin-bottom: 4px;
                    font-size: 16px;
                }
            }

            &__date {
                line-height: 160%;
                color: #D8D8D8;

                @include xs {
                    font-size: 8px;
                }
            }
        }

        &__description {
            @include xs {
                padding: 140px 20px 100px 20px;
            }

            @include sm {
                padding: 181px 0 64px 20px;
            }

            @media (min-width: 768px) and (max-width: 1439px) {
                width: 100%;
                max-width: 320px;
            }

            @include md {
                padding: 229px 0 190px 20px;
            }

            @include lg {
                padding: 290px 0 204px 20px;
                max-width: 403px;
            }

            .title {
                color: $white;

                @media (min-width: 768px) and (max-width: 1439px) {
                    margin-bottom: 20px;
                }

                @include lg {
                    font-size: 40px;
                    line-height: 59px;
                    margin-bottom: 10px;
                }
            }
        }

        &__text {
            color: #F1F2FF;

            @include xs {
                margin-bottom: 34px;
            }

            @include sm {
                margin-bottom: 30px;
            }

            @media (min-width: 768px) {
                font-size: 16px;
                line-height: 160%;
            }

            @include md {
                margin-bottom: 45px;
            }

            @include lg {
                margin-bottom: 28px;
            }
        }
    }
</style>