<template>
    <div class="form__group">
        <input 
            class="form__field"
            :class="{error: error}"
            :value="value"
            :type="type"
            :placeholder="placeholder"
            @input="$emit('eventInput', $event.target.value)"
        >

        <transition name="fade">
            <div v-if="error" class="form__group__error">
                {{ errorText }}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Field',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: String,
            default: ''
        },
        error: {
            type: Boolean,
            default: false
        },
        errorText: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
    .form {

        &__group {
            margin: 0 0 26px 0;
        }

        &__field {
            display: block;
            height: 58px;
            width: 100%;
            padding: 12px 15px;
            background: rgba(66, 66, 66, 0.7);
            border: 1px solid #616161;
            box-sizing: border-box;
            border-radius: 10px;
            font-size: 16px;
            color: $white;
            box-shadow: none;
            transition: 150ms ease-in-out;

            &::placeholder {
                color: $white;
                transition: 75ms ease-in-out;
            }

            &:focus::placeholder {
                opacity: 0;
            }

            &.error {
                border-color: $red;
            }
        }
    }
</style>