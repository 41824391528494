<template>
    <form class="form" :class="className">
        <slot></slot>

        <Input 
            type="text"
            placeholder="Name"
            :value="form.name"
            :error="$v.form.name.$error"
            @eventInput="($v.form.name.$model = $event, delayTouch($v.form.name))"
        />
        <Input 
            type="email"
            placeholder="Email"
            :value="form.email"
            :error="$v.form.email.$error || error !== null"
            @eventInput="($v.form.email.$model = $event, delayTouch($v.form.email))"
        />
        <div v-if="error" class="form__error">{{ error }}</div>
        <Checkbox 
            placeholder="I agree with License agreement"
            :error="$v.form.sign_agree.$error"
            :checked="form.sign_agree"
            @eventChange="$v.form.sign_agree.$model = $event"
        />
        <Button 
            class="button"
            text="Join Us"
            @eventClick="submit"
        />
    </form>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs, minLength, maxLength } from '@vuelidate/validators';
import Input from '@/components/Base/Input';
import Checkbox from '@/components/Base/Checkbox';
import Button from '@/components/Base/Button';

export default {
    name: 'Registration',
    props: {
        className: {
            type: String,
            default: '',
        }
    },
    components: {
        Input,
        Checkbox,
        Button
    },
    setup: () => ({
        $v: useVuelidate()
    }),
    data: () => ({
        form: {
            name: null,
            email: null,
            sign_agree: true,
        },
        error: null,
        wMap: new WeakMap(),
    }),
    validations: () => ({
        form: {
            name: { required, minLength: minLength(3), maxLength: maxLength(15) },
            email: { 
                required, 
                email
            },
            sign_agree: { sameAs: sameAs(true) }
        }
    }),
    methods: {
        getParams() {
            const url = new URL(document.location);
            const params = url.searchParams;

            Object.assign(this.form, {
                'pid': params.get('pid') || null,
                'pid_sid': params.get('sid') || null,
                'ip': this.$root.$ip,
            });
        },
        delayTouch($v) {
            $v.$reset();
            if ($v.email) this.error = null;
            if (this.wMap.has($v)) clearTimeout(this.wMap.get($v));
            this.wMap.set($v, setTimeout($v.$touch, 1000));
        },
        async submit() {
            try {
                this.$v.$touch();
                if (this.$v.$error) return;
                this.getParams();

                const data = new FormData();
                for (const key in this.form) {
                    data.append(key, this.form[key]);
                }

                const query = await fetch('/api/?action=registration', {
                    method: 'POST',
                    body: data,
                });
                const { loc, error } = await query.json();

                if (loc) window.location = loc;
                else if (error) this.error = error;
            } catch(error) {
                console.error(error);
            }
        }
    }
}
</script>

<style lang="scss">
    .form {

        &__error {
            margin: -4px 0 0 0;
            padding: 0 12px;
            font-size: 12px;
            text-align: right;
            color: $red;
        }
    }
</style>