<template>
    <footer class="footer">
        <div class="footer__wrapper">
            <div class="footer__payments">
                <img 
                    v-for="(payment, index) in payments"
                    :key="index"
                    :src="payment"
                    alt="#"
                >
            </div>
            <div class="footer__text">
                Online credit card processing by Paymentstrust (PCI / DSS certified Internet Payment Service Provider) and SegPay
            </div>
            <div class="footer__copyright">
                ©DatingGod2016-{{ (new Date()).getFullYear() }}
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data: () => ({
        payments: [
            require('@/assets/p-1.png'),
            require('@/assets/p-2.png'),
            require('@/assets/p-3.png'),
            require('@/assets/p-4.png')
        ]
    })
}
</script>

<style lang="scss">
    .footer {
        position: relative;
        overflow: hidden;
        background-color: #2D2C2C;

        @include xs {
            padding: 20px 20px 16px 20px;
        }

        @media (min-width: 768px) and (max-width: 1439px) {
            padding: 23px 0 12px 0;
        }

        @media (min-width: 1440px) {
            padding: 35px 0 12px 0;
        }

        &__wrapper {
            width: 100%;
            display: flex;
            align-items: center;

            @include xs {
                flex-direction: column;
                justify-content: center;
            }

            @include sm {
                max-width: 668px;
            }

            @media (min-width: 768px) {
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0 auto;
            }

            @include md {
                max-width: 882px;
            }

            @media (min-width: 1440px) {
                max-width: 822px;
                margin: 0 auto;
            }
        }

        &__payments {
            z-index: 3;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @include xs {
                margin: 0 -5px 24px -5px;
                width: calc(100% + 10px);
            }

            @media (min-width: 768px) {
                width: 50%;
                flex-shrink: 0;
            }

            img {
                max-width: 90px;
                height: fit-content;

                @include xs {
                    margin: 0 5px;
                    width: calc(25% - 10px);
                }

                @media (min-width: 768px) {
                    margin: 0 12.5px;
                    width: calc(25% - 25px);
                }
            }
        }

        &__copyright {
            z-index: 3;
            position: relative;
            display: flex;
            align-items: center;
            color: #A59F9F;

            @include xs {
                font-size: 10px;
                line-height: 17px;
            }

            @media (min-width: 768px) {
                width: 100%;
                flex-shrink: 0;
                justify-content: center;
                font-size: 12px;
                line-height: 20px;
            }

            @include sm {
                margin-top: 9px;
            }

            @include md {
                margin-top: 28px;
            }

            @include lg {
                margin-top: 16px;
            }
        }

        &__text {
            text-align: center;
            color: #FFFFFF;

            @include xs {
                margin: 0 0 12px 0;
                line-height: 118.2%;
            }

            @include sm {
                width: calc(50% - 20px);
            }

            @media (min-width: 768px) {
                flex-shrink: 0;
                font-size: 16px;
                line-height: 19px;
                text-align: left;
                padding-left: 20px;
            }

            @media (min-width: 992px) {
                width: 50%;
            }
        }
    }
</style>