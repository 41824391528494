<template>
    <header class="header">
        <img class="header__logotype" :src="require('@/assets/logo.svg')" alt="Easy Dating" />
        <div class="header__text">
            <div class="header__text__top">Do you want</div>
            <div class="header__text__middle">the girl</div>
            <div class="header__text__bottom">of your dreams?</div>
        </div>
        <div class="header__label">
            <img :src="require('@/assets/Subtract.png')" alt="#">
            Jenny Wilson, 25 y.o
        </div>
        <picture class="header__background">
            <source media="(min-width:1440px)" :srcset="require('@/assets/bg_1440.png')">
            <source media="(min-width:992px)" :srcset="require('@/assets/bg_992.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/bg_768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/bg_320.png')">
            <img :src="require('@/assets/bg_1440.png')" alt="Easy Dating">
        </picture>
        <img :src="require('@/assets/bg_frame.png')" alt="#" class="header__frame">
        <div class="header__registration">
            <picture class="header__registration__background">
                <source media="(min-width:768px)" :srcset="require('@/assets/bg_bottom_768.png')">
                <source media="(min-width:320px)" :srcset="require('@/assets/bg_bottom_320.png')">
                <img :src="require('@/assets/bg_bottom_768.png')" alt="Easy Dating">
            </picture>
            <Registration className="header__form">
                <h1 class="title title--h1 ta-center" id="head">
                    JOIN OUR CLUB
                </h1>
            </Registration>
        </div>
    </header>
</template>

<script>
import Registration from '@/components/Registration';

export default {
    name: 'Header',
    components: {
        Registration,
    }
}
</script>

<style lang="scss">
    .header {
        position: relative;
        display: flex;
        flex-direction: column;

        @include xs {
            margin-bottom: 23px;
        }

        @include md {
            max-width: 882px;
            margin: 0 auto;
        }

        @include lg {
            max-width: 1190px;
            margin: 0 auto;
        }

        &__logotype {

            @include xs {
                width: 172px;
                margin: 18px 20px 168px auto;
            }

            @include sm {
                width: 172px;
                margin: 46px 50px 228px auto;
            }

            @media (min-width: 992px) {
                width: 229px;
                height: 60px;
            }

            @include md {
                margin: 50px 0 0 auto;
            }

            @include lg {
                margin: 55px 58px 0 auto;
            }
        }

        &__text {
            position: relative;
            z-index: 3;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;

            @include xs {
                margin: 0 0 36px 20px;
            }

            @include sm {
                margin: 0 0 96px 85px;
            }

            @media (min-width: 992px) {
                position: absolute;
            }

            @include md {
                top: 324px;
                left: 35px;
            }

            @include lg {
                top: 329px;
                left: 0;
            }

            &::before {
                content: '';
                position: absolute;
                background: #18A815;
                box-shadow: 0px 8px 15px rgba(37, 194, 23, 0.25);
                border-radius: 100%;

                @media (max-width: 991px) {
                    top: -12px;
                    left: -14px;
                    width: 16px;
                    height: 16px;
                }

                @media (min-width: 992px) {
                    top: -30px;
                    left: -34px;
                    width: 27px;
                    height: 27px;
                }
            }

            &__top {
                text-shadow: 0px 4px 30px rgba(64, 61, 61, 0.5);
                line-height: 116%;
                text-transform: none;

                @include xs {
                    font-size: 34px;
                }

                @include sm {
                    font-size: 50px;
                }

                @include md {
                    font-size: 50px;
                }

                @include lg {
                    font-size: 50px;
                }
            }

            &__middle {
                text-shadow: 0px 6px 25px rgba(0, 0, 0, 0.35);

                @include xs {
                    margin: -15px 0 -10px 0;
                    font-size: 70px;
                    line-height: 1;
                }

                @include sm {
                    margin: -40px 0;
                    font-size: 120px;
                    line-height: 146%;
                }

                @include md {
                    margin: -40px 0;
                    font-size: 120px;
                    line-height: 146%;
                }

                @include lg {
                    margin: -50px 0;
                    font-size: 151px;
                }
            }

            &__bottom {
                line-height: 118%;
                text-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);

                @include xs {
                    font-size: 31px;
                }

                @include sm {
                    font-size: 46px;
                }

                @include md {
                    font-size: 46px;
                }

                @include lg {
                    font-size: 61px;
                }
            }
        }

        &__label {
            z-index: 3;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #F7F4FD;
            font-weight: 500;
            background: rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(7px);
            line-height: 160%;
            border-radius: 50px;

            @include xs {
                width: 134px;
                height: 33px;
                padding: 0 12px;
                margin: 0 20px 27px auto;
                font-size: 10px;

                img {
                    width: 19px;
                    height: 19px;
                }
            }

            @include sm {
                width: 185px;
                height: 47px;
                padding: 0 14px;
                margin: 0 50px 37px auto;
                font-size: 14px;

                img {
                    width: 31px;
                    height: 31px;
                }
            }

            @media (min-width: 992px) {
                position: absolute;
                width: 185px;
                height: 47px;
                justify-content: center;
                filter: drop-shadow(0px 6px 10px rgba(19, 18, 19, 0.25));

                img {
                    width: 31px;
                    height: 31px;
                    margin: 0 10px 0 0;
                }
            }

            @include md {
                bottom: 133px;
                left: 50%;
                transform: translateX(-50%);
            }

            @include lg {
                bottom: 146px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__frame {

            @media (max-width: 1439px) {
                display: none;
            }

            @include lg {
                z-index: 2;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 100vw;
                height: auto;
                max-width: unset;
            }
        }

        &__background {
            z-index: -1;
            position: absolute;
            top: -2px;
            left: -3px;
            width: calc(100% + 3px);
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                max-width: unset;
                object-fit: cover;
                object-position: center top;

                @media (min-width: 992px) {
                    object-position: center bottom;
                }

                @include lg {
                    object-position: center top;
                }
            }

            @media (min-width: 992px) {
                height: 100%;
                width: 100vw;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__registration {
            z-index: 2;
            position: relative;

            &::after {
                @media (max-width: 991px) {
                    content: '';
                    position: absolute;
                    top: -199px;
                    left: 0;
                    width: 100vw;
                    height: 200px;
                    background: linear-gradient(0deg, #000, rgba(0, 0, 0, .0));
                }

                @media (max-width: 374px) {
                    top: -299px;
                    height: 300px;
                    background: linear-gradient(0deg, #000 20%, rgba(0, 0, 0, .0));
                }

                @include sm {
                    height: 300px;
                    background: linear-gradient(0deg, #000 45%, rgba(0, 0, 0, 0));
                }
            }

            @include xs {
                padding: 0 20px 90px 20px;

                .form {
                    margin: 0 auto;
                    max-width: 437px;
                }
            }

            @include sm {
                padding: 0 20px 90px 20px;

                .form {
                    margin: 0 auto;
                    max-width: 437px;
                }
            }

            @include md {
                width: 331px;
                margin: 0 0 248px auto;
            }

            @include lg {
                width: 383px;
                margin: 0 0 262px auto;
            }

            &__background {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center bottom;

                    @include sm {
                        height: 110%;
                    }
                }

                @media (min-width: 992px) {
                    display: none;
                }
            }
        }

        &__form {
            position: relative;
            z-index: 3;
        }
    }

    // @include xs {

    // }

    // @include sm {
        
    // }

    // @include md {
        
    // }

    // @include lg {
        
    // }
</style>